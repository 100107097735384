//@ts-nocheck
import React from 'react';

import {
  DivText,
  Grid,
  Container,
  PFirst,
  PSecond,
  Wrapper,
  IMG,
  WrapperImg,
  Combo,
  WrapperImgMobile,
  ComboMobile,
  IMGMobile,
  Card,
} from './styles';
import imgHome from '../../../assets/services.png';
import imgHomeMObile from '../../../assets/1.jpeg';
import { useNavigate } from 'react-router-dom';

const Page1: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Grid>
        <Card href="/cleaning-services">
          Cleaning Services
          <br /> Home & Office
        </Card>
        <Card href="/pool-services">
          Pool Services
          <br />
          Maintaince & Cleaning
        </Card>
        <Card href="/handyman-services">Handyman Services</Card>
        <Card href="paiting-services">Painting Services</Card>
      </Grid>
    </Container>
  );
};

export default Page1;
