//@ts-nocheck
import React, { useEffect, useState } from 'react';

import { DivText, Grid, Container, Wrapper } from './styles';
import imgHome from '../../../assets/images/home/page4/img_faxina.png';

const Page5: React.FC = () => {
  return (
    <Container>
      <Grid image={imgHome}>
        <DivText>
          <Wrapper>
            <p>Everything you need.</p>
            <p>All home services in one place. Guaranteed and safe.</p>
            <button
              style={{ cursor: 'pointer' }}
              onClick={() =>
                window.open('https://dashboard.xgreen.app', '_blank')
              }
            >
              <a href="https://dashboard.xgreen.app" target="_blank">
                BOOK NOW
              </a>
            </button>
          </Wrapper>
        </DivText>
      </Grid>
    </Container>
  );
};

export default Page5;
