import React, { useState } from 'react';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';

import Page5 from '../../components/Pool/Page5';

const Pool: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  document.title = 'Xgreen - Pool Services';
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Page5 />
      {/* <Page1/>
      <Page2/>
      <Page3/>
      <Page4/> */}
      <Footer />
    </>
  );
};

export default Pool;
