import styled from 'styled-components';

type Props = {
  image: any;
  imageMobile: any;
};
export const Container = styled.div`
  /* box-sizing: border-box; */
  /* min-height: 80vh !important; */
  min-width: 100vw;
  padding: 80px 0 0 130px;
  margin-bottom: 200px;
  @media screen and (max-width: 1380px) {
    padding: 80px 0 0 80px;
  }
  @media screen and (max-width: 680px) {
    padding: 20px;
    min-width: 100%;
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  h1 {
    max-width: 430px;
    font-family: 'avenir_nextbold';
    font-size: 45px;
    line-height: 50px;
  }
  @media screen and (max-width: 680px) {
    h1 {
      width: 100%;
    }
  }
`;

export const Hr = styled.div`
  border: none;
  background-color: #000;
  width: 94px;
  opacity: 1;
  height: 9px;
  margin-top: 15px;
  @media screen and (max-width: 1050px) {
    margin-top: 20px;
  }
`;
export const Text = styled.p`
  font-family: 'avenir_nextregular';
  font-size: 20px;
  line-height: 26px;
  max-width: 90%;
  display: flex;
  flex-wrap: wrap;
  strong {
    font-family: 'avenir_nextbold';
  }

  @media screen and (max-width: 680px) {
    max-width: 85%;
    padding: 10px;
  }
`;
