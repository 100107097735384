//@ts-nocheck
import React, { useEffect, useState } from 'react';
// import Container from '@mui/material/Container';
// import { makeStyles, Theme } from '@mui/styles';
// import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
// import { styled } from '@mui/material/styles';
// import goey from '../../../assets/images/home/page5/goeyja_MotoGrupo.png';
// import { FormControl } from '@mui/material';
// import { Col, Container, Row } from 'react-bootstrap';

import {
  DivText,
  Grid,
  Container,
  DivImg,
  P,
  PFirst,
  PSecond,
  Wrapper,
  IMG,
  SmallText,
} from './styles';
import cels from '../../../assets/xgreenScreens_@2x.png';
import apple from '../../../assets/images/apple.png';
import android from '../../../assets/images/android.png';

const Page1: React.FC = () => {
  return (
    <Container>
      <Grid>
        <DivText>
          <PFirst>Refer a client. Get rewarded.</PFirst>

          <hr
            style={{
              height: '9px',
              width: '90px',
              margin: 0,
              backgroundColor: '#000',
            }}
          />

          <SmallText>Scroll down to learn</SmallText>
        </DivText>

        <div
          style={{
            height: '100%',
            border: 'none',
          }}
        >
          <IMG image={cels} />
        </div>
      </Grid>
    </Container>
  );
};

export default Page1;
