//@ts-nocheck
import React, { useEffect, useState } from 'react';

import { Grid, Container, Hr, Text } from './styles';

const Page1: React.FC = () => {
  return (
    <Container>
      <Grid>
        <h1>Payment Policy</h1>
        <Hr />
        <Text>
          At Xgreen, we strive to make our services accessible and convenient
          for everyone. Our payment policy ensures a seamless experience for all
          our customers. Please take a moment to review the available payment
          options and terms:
          <br />
          <br />
          <strong>Accepted Payment Methods</strong>
          We accept the following methods of payment: Credit and Debit Cards
          <br />
          <br /> We accept major credit and debit cards, including Visa,
          Mastercard, American Express, and Discover. Payments can be made
          securely through our platform from anywhere in the world.
          <br />
          <br />
          <strong>Zelle®️ (USA only)</strong>
          For customers within the United States, we also offer the option to
          pay via Zelle®️. Please note that Zelle payments are only available in
          USD and can be completed by using the email address linked to your
          account. Payments via Zelle must be initiated directly from your bank
          app or website.
        </Text>
      </Grid>
    </Container>
  );
};

export default Page1;
