//@ts-nocheck
import React, { useEffect, useState } from 'react';

import { Grid, Container } from './styles';
import Accordion from './Components/Accordion';
import Tabs from './Components/Tabs';

const Page5: React.FC = () => {
  const [openAccordion, setOpenAccordion] = useState<number | null>(null);

  const handleAccordionToggle = (index: number) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const tabData = [
    {
      label: 'PAINTING SERVICES',
      description:
        'Our mission is simple: complete customer satisfaction. A fresh coat of paint can breathe new life into your home or business, creating a clean, vibrant atmosphere that reflects your style. No job is too big or small for the Xgreen! Give your property a new lease on life with Xgreen’s where professional painting service meets stunning results.',
      content: (
        <>
          <Accordion
            title="Interior Painting"
            content={{
              column1: [
                'For all of your interior painting needs. Call today or book a visit for a FREE in-home estimate.',
              ],
            }}
            isOpen={openAccordion === 0}
            onToggle={() => handleAccordionToggle(0)}
          />

          <Accordion
            title="Exterior Painting"
            content={{
              column1: [
                'Call or book a visit for all of your exterior painting needs. We serve both commercial and residential customers, and we respond to calls and emails within 24 hours.',
              ],
            }}
            isOpen={openAccordion === 1}
            onToggle={() => handleAccordionToggle(1)}
          />

          <Accordion
            title="Commercial Painting"
            content={{
              column1: [
                'If you need a painting contractor for a commercial renovation project, no matter how big or little, we have the skills and knowledge to get the work done quickly and efficiently.',
              ],
            }}
            isOpen={openAccordion === 2}
            onToggle={() => handleAccordionToggle(2)}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Container>
        <Grid>
          <h5>Painting Services</h5>
          <p>
            When it comes to painting, trust the experts at XGreen’s to deliver
            exceptional results, every time. Whether it’s a residential or
            commercial project, we handle all your interior and exterior
            painting needs with precision and care.
          </p>
        </Grid>
      </Container>
      <Tabs tabs={tabData} />
    </>
  );
};

export default Page5;
