import styled from 'styled-components';

type Props = {
  image: any;
  imageMobile: any;
};

export const Container = styled.div`
  /* box-sizing: border-box; */
  min-height: 80vh !important;
  min-width: 100vw;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  grid-gap: 15px;
  min-height: calc(100vh - 80px);
  width: 100%;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;

  p {
    display: none;
  }
  div:nth-of-type(1) {
    justify-content: flex-end;
  }
  @media screen and (max-width: 1050px) {
    p {
      display: block;
      font-family: 'avenir_nextbold';
      font-size: 45px;
      text-align: left;
      line-height: 50px;
      margin: 30px 10px 0 10px;
    }
    grid-gap: 5px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Wrapper = styled.div<Props>`
  min-height: 375px;
  border-radius: 25px;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  @media screen and (max-width: 1050px) {
    padding-right: 0px;
    background-position: bottom center;
    min-height: 300px;
    margin: 40px 10px 0 10px;
    background-image: url(${(props) => props.imageMobile});
  }
`;
export const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15%;

  h1 {
    font-family: 'avenir_nextbold';
    font-size: 45px;
    text-align: left;
    line-height: 50px;
    width: 429px;

    color: #000;
  }
  span {
    font-family: 'avenir_nextregular';
    font-size: 45px;
    text-align: left;
    line-height: 50px;
    width: 429px;

    color: #000000;
  }
  strong {
    /* background-color: #00d872; */
    width: 318px;
    height: 62px;
    border: none;
    margin-top: 10px;
    color: #00d872;
    font-family: 'avenir_nextbold';
    font-size: 20px;
    line-height: 27px;
    margin-top: 21px;
  }

  @media screen and (max-width: 1050px) {
    width: 90%;
    margin-left: 3%;
    padding-left: 3%;

    span {
      width: 100%;
      font-size: 35px;
      text-align: center;
      color: #000;
    }

    h1 {
      width: 100%;
      font-size: 35px;
      text-align: center;
      line-height: 40px;
    }

    small {
      width: 100%;
    }

    button {
      align-self: center;
    }
  }
`;
export const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  h1 {
    font-family: 'avenir_nextbold';
    font-size: 45px;
    text-align: left;
    line-height: 50px;
    margin: 0px;
  }

  span {
    font-family: 'avenir_nextregular';
    font-size: 35px;
    text-align: left;
    line-height: 40px;
    margin-top: 10px;
  }

  small {
    margin-top: 20px;
    font-family: 'avenir_nextregular';
    font-size: 16px;
    text-align: left;
    line-height: 26px;
  }

  @media screen and (max-width: 1050px) {
    span {
      width: 100%;
    }
    h1 {
      width: 100%;
    }
    small {
      width: 100%;
    }
  }
`;
