import React from 'react';
import styled from 'styled-components';
import { CiCirclePlus, CiCircleMinus } from 'react-icons/ci';

const AccordionContainer = styled.div`
  margin-bottom: 10px; /* Adiciona espaço entre os itens do accordion */
  span {
    font-family: 'avenir_nextbold';
    font-size: 20px;
    line-height: 27px;
  }
`;

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0; /* Remove o padding da esquerda */
  cursor: pointer;
  position: relative;
  border-bottom: 2px solid #000; /* Sempre mostra a borda inferior */
`;

const AccordionContent = styled.div<{ expanded: boolean }>`
  max-height: ${({ expanded }) => (expanded ? '1000px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease;
`;

interface AccordionProps {
  title: string;
  content: {
    column1: string[];
    column2: string[];
    column3: string[];
  };
  isOpen: boolean;
  onToggle: () => void;
}

const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const AccordionText = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 290px;
  margin: 0px;
  font-family: 'avenir_nextregular';
  font-size: 20px;
  line-height: 27px;
`;

const AccordionContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Espaçamento entre as colunas */
  @media screen and (max-width: 780px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  flex: 1;
  /* Ajusta a largura mínima das colunas para evitar que fiquem muito pequenas */
  min-width: 200px;
  ul {
    padding-inline-start: 0px;
    list-style-type: none;
  }
`;

const ListItem = styled.li`
  margin-bottom: 10px; /* Espaçamento entre os itens da lista */
  font-family: 'avenir_nextregular';
  font-size: 20px;
`;

const Accordion: React.FC<AccordionProps> = ({
  title,
  content,
  isOpen,
  onToggle,
}) => {
  const { column1 = [], column2 = [], column3 = [] } = content || {};

  return (
    <AccordionContainer>
      <AccordionHeader onClick={onToggle}>
        <span>{title}</span>
        {isOpen ? (
          <CiCircleMinus size={30} strokeWidth={0.75} />
        ) : (
          <CiCirclePlus size={30} strokeWidth={0.75} />
        )}
      </AccordionHeader>
      <AccordionContent expanded={isOpen}>
        {/* <div>{content}</div> */}
        <AccordionContentWrapper>
          <Column>
            <ul>
              {column1.map((item, index) => (
                // <ListItem key={index}>{item}</ListItem>
                <ListItem key={index}>
                  {<div dangerouslySetInnerHTML={{ __html: item }} />}
                </ListItem>
              ))}
            </ul>
          </Column>
        </AccordionContentWrapper>
      </AccordionContent>
    </AccordionContainer>
  );
};

export default Accordion;
