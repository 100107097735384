import React from 'react';
// @ts-nocheck
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import Service from './pages/Service';
import Faq from './pages/Faq';
import Work from './pages/Work';
import Costumer from './pages/Costumer';
import Goal from './pages/Goal';
import ScrollToTop from './components/ScrollToTop';
import Review from './pages/Review';
import Refer from './pages/Refer';
import Location from './pages/Location';
import ServiceRef from './pages/ServiceRef';
import Pool from './pages/Pool';
import Handyman from './pages/Handyman';
import { ToastContainer } from 'react-toastify';
import Painting from './pages/Painting';
import Day from './pages/Day';
import Payment from './pages/Payment';
import Contact from './pages/Contact';
import About from './pages/About';

const Rotas = () => (
  <BrowserRouter>
    <ScrollToTop>
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/services" element={<ServiceRef />} />
        <Route path="/cleaning-services" element={<Service />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/providers" element={<Work />} />
        <Route path="/customers" element={<Costumer />} />
        <Route path="/our-goals" element={<Goal />} />
        <Route path="/pool-services" element={<Pool />} />
        <Route path="/handyman-services" element={<Handyman />} />
        <Route path="/reviews" element={<Review />} />
        <Route path="/rewards" element={<Refer />} />
        <Route path="/locations" element={<Location />} />
        <Route path="/paiting-services" element={<Painting />} />
        <Route path="/day" element={<Day />} />
        <Route path="/payment-policy" element={<Payment />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
      <ToastContainer />
    </ScrollToTop>
  </BrowserRouter>
);

export default Rotas;
