//@ts-nocheck
import React, { useEffect, useState } from 'react';

import { Grid, Container, Wrapper, WrapperIcon, Content } from './styles';
import imgHome from '../../../assets/images/work/page1/professional_.png';
import imgHomeMobile from '../../../assets/images/work/page1/mobile/professional_.png';
import { Link } from 'react-router-dom';

const Page1: React.FC = () => {
  return (
    <Container>
      <Grid>
        <Wrapper image={imgHome} imageMobile={imgHomeMobile}>
          <p>
            Hiring a specialist cleaning team to help you maintain your space.
          </p>
          {/* <p>
            <Link to="/review">__Customer Testimonials</Link>
          </p> */}
        </Wrapper>

        <div>
          <WrapperIcon>
            <p>Make savings on costs and time.</p>
            <span>
              One the most advantageous benefits of using contract cleaning
              services is improved levels of health and safety.
            </span>
          </WrapperIcon>

          <WrapperIcon>
            <p>Benefit from the expertise of a specialist!</p>
            <span>
              You will take advantage of a wealth of experience and expertise.
            </span>
          </WrapperIcon>

          <WrapperIcon>
            <p>No need to worry.</p>
            <span>Effortless cleaning, exceptional results.</span>
          </WrapperIcon>
        </div>
      </Grid>
    </Container>
  );
};

export default Page1;
