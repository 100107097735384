//@ts-nocheck
import React, { useEffect, useState } from 'react';

import { Grid, Container } from './styles';
import Accordion from './Components/Accordion';
import Tabs from './Components/Tabs';

const Page5: React.FC = () => {
  const [openAccordion, setOpenAccordion] = useState<number | null>(null);

  const handleAccordionToggle = (index: number) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const tabData = [
    {
      label: 'MAINTENANCE AND CLEANING',
      description:
        'Office maintenance and cleaning. This involves cleaning larger areas with more foot traffic, often requiring specialized cleaning equipment and methods to maintain a sanitary and safe environment. A clean and professional-looking workplace is essential to a company’s image, reputation, and health and safety concerns.',
      content: (
        <>
          <Accordion
            title="Maintenance and Cleaning"
            content={{
              column1: [
                'Chemical chopping and balancing.',
                'Equipment monitoring. Wall, step and counter top scrubbing.',
                'Pump basket emptying.',
              ],
              column2: [
                'Pump basket emptying.',
                'Skimmer cleaning In-line leaf collector emptying.',
                'Single cartridge cleaning/monthly DE/sand backwash.',
                'Surface netting.',
              ],
              column3: ['Tile brushing.', 'Vacuuming.'],
            }}
            isOpen={openAccordion === 0}
            onToggle={() => handleAccordionToggle(0)}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Container>
        <Grid>
          <h5>Pool Services</h5>
          <p>
            Keep your pool sparkling clean with our reliable residential and
            commercial cleaning services. Our expert team ensures a pristine
            pool environment, so you can relax and enjoy. Convenient scheduling.
          </p>
        </Grid>
      </Container>
      <Tabs tabs={tabData} />
    </>
  );
};

export default Page5;
