//@ts-nocheck
import React, { useEffect, useState } from 'react';

import { Grid, Container, Wrapper } from './styles';
import Accordion from './Accordion';
import Tabs from './Tabs';
import { Title } from './styles';

const Page1: React.FC = () => {
  const [openAccordion, setOpenAccordion] = useState<number | null>(null);

  const handleAccordionToggle = (index: number) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const tabData = [
    {
      label: 'Location',
      description: '',

      content: (
        <Wrapper>
          <Title>Locations</Title>
          <Accordion
            title="Florida"
            content={{
              column1: [
                'Alafaya',
                'Altamonte Springs',
                'Apopka',
                'Celebration',
                'Clermont',
                'Davenport',
                'Dr. Philipis',
              ],
              column2: [
                'Hunters Creek ',
                'Kissimmie ',
                'Lake Buena Vista ',
                'Lake Nona ',
                'Maitland ',
                'Minneola ',
                'Montverde',
              ],
              column3: [
                'Oakland ',
                'Ocoee ',
                'Orlando ',
                'Oviedo ',
                'Windermere ',
                'Winter Garden ',
                'Winter Park',
              ],
            }}
            isOpen={openAccordion === 6}
            onToggle={() => handleAccordionToggle(6)}
          />
        </Wrapper>
      ),
    },
  ];

  return (
    <>
      <Tabs tabs={tabData} />;
    </>
  );
};

export default Page1;
