//@ts-nocheck
import React, { useEffect, useState } from 'react';

import { Grid, Container, Hr, Text } from './styles';
import Accordion from './Components/Accordion';
import Tabs from './Components/Tabs';

const Page1: React.FC = () => {
  const [openAccordion, setOpenAccordion] = useState<number | null>(null);

  const handleAccordionToggle = (index: number) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const tabData = [
    {
      label: '',
      description: '',
      content: (
        <>
          <Accordion
            title="Who We Are"
            content={{
              column1: [
                'Founded with a vision to transform the cleaning service industry, Xgreen provides easy-to-book, reliable, and eco-friendly cleaning services. Our team is a diverse group of passionate professionals committed to excellence. We’ve grown from a startup into a community-driven business that emphasizes both customer satisfaction and the well-being of our cleaning partners. By utilizing electric vehicles and eco-friendly products, we reduce our carbon footprint and help you maintain a clean, healthy home without compromising the planet.',
              ],
            }}
            isOpen={openAccordion === 0}
            onToggle={() => handleAccordionToggle(0)}
          />

          <Accordion
            title="What We Do"
            content={{
              column1: [
                `XGreen offers top-quality home services for both residential and vacation homes in the Orlando area. Whether you need a deep clean, regular upkeep, or a specialized service, we’ve got you covered.<br/><br/>
                <strong>• Residential Cleaning:</strong> We help homeowners and tenants maintain spotless, fresh homes with scheduled or one-time cleaning services tailored to your needs.<br/>
                <strong>• Vacation Home Cleaning:</strong> Hosting guests or maintaining a rental property? We offer reliable, high-quality cleaning services designed to ensure your property is always guest-ready.<br/>
                <strong>• Office Cleaning:</strong> In addition to homes, we also extend our services to small businesses and offices looking for eco-friendly and efficient cleaning solutions.<br/>`,
              ],
            }}
            isOpen={openAccordion === 1}
            onToggle={() => handleAccordionToggle(1)}
          />

          <Accordion
            title="How We Serve People"
            content={{
              column1: [
                `At Xgreen, we make the process of booking home services simple and straightforward. Our platform is user-friendly and can be accessed from anywhere in the world. With just a few clicks, you can schedule, customize, and pay for the services you need.`,
              ],
            }}
            isOpen={openAccordion === 2}
            onToggle={() => handleAccordionToggle(2)}
          />
        </>
      ),
    },
  ];

  return (
    <Container>
      <Grid>
        <h1>About us</h1>
        <Text>
          Welcome to Xgreen, your trusted partner for home services in the
          Orlando, Florida area. At Xgreen, we are driven by a passion for
          convenience, sustainability, and social responsibility, making us the
          go-to platform for home and commercial cleaning solutions.
        </Text>
        <Tabs tabs={tabData} />
      </Grid>
    </Container>
  );
};

export default Page1;
