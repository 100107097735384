import React, { useState } from 'react';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';

import Page1 from '../../components/Work/Page1';
import Page2 from '../../components/Work/Page2';
import { Page3 } from '../../components/Work/Page3';
// import Page3 from '../../components/Work/Page3'

const Home: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  document.title = 'Xgreen - Providers';
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      {/* <Page1 /> */}
      <Page2 />
      <Page3 />
      <Footer />
    </>
  );
};

export default Home;
