import styled from 'styled-components';

type Props = {
  image: any;
};
export const Container = styled.div`
  /* box-sizing: border-box; */
  min-height: 80vh !important;

  padding: 32px 8.33%;

  h5 {
    font-family: 'avenir_nextbold';
    font-size: 45px;
    text-align: left;
    line-height: 50px;
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 50px;
`;
export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  align-self: stretch;
  width: 100%;
  /* @media screen and (max-width: 1920px) {
    width: 580px !important;
  }
  @media screen and (max-width: 1380px) {
    width: 500px !important;
  }
  @media screen and (max-width: 1280px) {
    width: 450px !important;
  } */
  h1 {
    color: #000;
    font-family: 'avenir_nextregular' !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
  }
`;

export const WrapperStar = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin: 10px 0 25px 0;
`;

export const BoxText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  p:nth-child(1) {
    font-family: 'avenir_nextbold';
    font-size: 20px;
    text-align: left;
    line-height: 27px;
    margin: 0px;
  }

  p:nth-child(2) {
    font-family: 'avenir_nextregular';
    font-size: 16px;
    text-align: left;
    line-height: 26px;
    margin: 0px;
  }
  p:nth-child(3) {
    font-family: 'avenir_nextregular';
    font-size: 20px;
    text-align: left;
    line-height: 26px;
    color: #000000;
    margin: 0px;
  }
`;

export const Hr = styled.hr`
  margin-top: 20px;
  width: 100%;
  border: 1px solid #707070;
`;
