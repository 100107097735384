//@ts-nocheck
import React, { useEffect, useState } from 'react';
// import Container from '@mui/material/Container';
// import { makeStyles, Theme } from '@mui/styles';
// import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
// import { styled } from '@mui/material/styles';
// import goey from '../../../assets/images/home/page5/goeyja_MotoGrupo.png';
// import { FormControl } from '@mui/material';
// import { Col, Container, Row } from 'react-bootstrap';

import {
  DivText,
  Grid,
  Container,
  DivImg,
  P,
  PFirst,
  PSecond,
  Wrapper,
  IMG,
  NewMessage,
  NewStrong,
  Box,
  WrapperBox,
  Input,
} from './styles';
import imgHome from '../../../assets/images/home/img_homeServices.png';
import apple from '../../../assets/images/apple.png';
import android from '../../../assets/images/android.png';

import { useForm } from 'react-hook-form';
import QuickQuoteForm from './QuickQuoteForm';

const Page1: React.FC = () => {
  return (
    <Container>
      <Grid>
        <DivText>
          <Wrapper>
            {/* <PFirst>Get your quote.</PFirst> */}
            <PSecond>
              Your go-to solution for all your home services needs.
            </PSecond>
            <NewMessage>
              At Xgreen, your safety is our priority. Our trained professionals
              are background-checked for your peace of mind.
            </NewMessage>
          </Wrapper>

          <DivImg>
            <a
              href="https://play.google.com/store/apps/details?id=com.mindconsulting.xgreen&hl=en"
              target="_blank"
            >
              <img src={android} />
            </a>
            <a
              href="https://apps.apple.com/br/app/xgreen/id6473839103"
              target="_blank"
            >
              <img src={apple} />
            </a>
          </DivImg>
        </DivText>
        {/* <WrapperBox> */}
        <QuickQuoteForm />
        {/* </WrapperBox> */}
      </Grid>
    </Container>
  );
};

export default Page1;

{
  /* <Input
placeholder="NAME"
type="text"
{...register('name', { required: true })}
/> */
}
