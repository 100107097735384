//@ts-nocheck
import React, { useEffect, useState } from 'react';

import { Grid, Container, Wrapper, WrapperForm } from './styles';
import smallTruck from '../../../assets/images/goal/page2/xgreen_F150-Trailler@2x.png';

const Page1: React.FC = () => {
  const data = [
    {
      img: smallTruck,
      title: '100% Electric Cars.',
      sub: 'We come to your home with minimal environmental impact.',
      btn: 'Less CO² emission',
    },
  ];

  return (
    <Container>
      {/* {data.map((value: any, index: number) => { */}
      {/* return ( */}
      <Grid>
        <Wrapper image={smallTruck} imageMobile={smallTruck} />

        <WrapperForm>
          <h1>100% Electric Cars.</h1>
          <span>We come to your home with minimal environmental impact.</span>
          <strong>Less CO² emission</strong>
        </WrapperForm>
      </Grid>
      {/* ); */}
      {/* })} */}

      {/* </Grid> */}
    </Container>
  );
};

export default Page1;
