import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
// import { Link as LinkR } from 'react-scroll';

//DESKTOP
export const Nav = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? 'transparent' : 'transparent')};
  height: 80px;
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color:#000;
  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
    background: #000;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 70px;
  z-index: 1;
  width: 100vw;
  padding: 0 24px;
  /* max-width: 1185px !important; */
`;

export const NavLogo = styled(LinkR)`
  color: #fff;
  justify-self: flex-start !important;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 15px;
  font-weight: bold;
  text-decoration: none;
  margin-left: 30px;

  img {
    width: 100px;
  }

  @media screen and (max-width: 768px) {
    margin-left: 0px;
  }
`;

export const NavHam = styled.div`
  color: #fff;
  justify-self: flex-end !important;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 15px;
  font-weight: bold;
  text-decoration: none;
  margin-left: 30px;

  img {
    width: 47px;
  }

  @media screen and (max-width: 768px) {
    margin-left: 0px;
  }
`;
export const NavLogin = styled.div`
  color: #fff;
  justify-self: flex-end;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 15px;
  font-weight: bold;
  text-decoration: none;
  margin-left: 30px;
  width: 70%;
  img {
    width: 84px;
  }

  @media screen and (max-width: 856px) {
   display:none !important;
  }
`;
export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 10px;
    right: 0;
    transform: translate(-100%, 30%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
    /* margin-top: 13px; */
  }
`;

export const NavMenu = styled.ul`
  //DESKTOP
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  /* margin-right: -22px; */
  /* font-weight: bold; */
  margin:0px !important;
  //RESPONSIVE
  @media screen and (max-width: 768px) {
    display: none;
  }

`;

export const NavItem = styled.li`
  height: 40px;
  color:#fff;
 
`;

export const NavLinks = styled(LinkR)`
  color:#000 !important;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-family:'avenir_nextregular';
  &:hover {
    font-weight: bold;
  }

  &.active {
    border-bottom: 3px solid #f57c00;
  }
`;

export const NavBtn = styled.nav`
  //DESKTOP
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: #f57c00;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`;


export const WrapperButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap:10px;
  align-items:center;
  margin-right:24px;
  @media screen and (max-width: 768px) {
    display: none;
  }
  
`

export const ButtonWhite = styled.button`
      width: 206px;
      height: 45px;
      background-color: #FFFFFF;
      color:#000;
      border: transparent;
      font-family: 'avenir_nextbold';
      cursor: pointer;
`
export const ButtonGreen = styled.button`
      width: 206px;
      height: 45px;
      background-color: #9DFDCA;
      color:#000;
      border: transparent;
      font-family: 'avenir_nextbold';
      cursor: pointer;
      font-size:16px;
      line-height: 26px;
      a{
        color:#000 !important;
      }
      
`
export const ButtonBlack = styled.button`
      width: 206px;
      height: 45px;
      background-color: #000;
      border: transparent;
      font-family: 'avenir_nextbold';
      cursor: pointer;
      color:#fff !important;
`


export const WrapperBar = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background-color: #f5f5f5;
  padding: 0 24px;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
  }
  position: sticky;
  top: 80px;
  z-index: 10;
`

export const BarMenu = styled.div`
  font-family:'avenir_nextbold';
  font-weight: bold;
  font-size:16px;
  color:#000;
  @media screen and (max-width: 768px) {
    font-size:12px;
  }
  @media screen and (max-width: 1280px) {
    font-size:13px;
  }
`