import styled from 'styled-components';

import img from '../../../assets/bg_homeForm@2x.png';
type Props = {
  image: any;
};
export const Container = styled.div`
  /* box-sizing: border-box; */
  min-height: 80vh !important;
  min-width: 100vw;
  background-image: url(${img});
`;

export const Grid = styled.div`
  display: inline-grid;
  grid-template-columns: 45% 45%;
  grid-gap: 30px;
  min-height: calc(100vh - 80px);
  width: 100%;
  /* background-color: #01602e; */

  /* position: relative; */
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1050px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* div:nth-of-type(1) {
      order: 1;
    }
    div:nth-of-type(2) {
      order: 2;
    } */
  }
`;
export const DivText = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
  padding-left: 25%;

  @media screen and (max-width: 1050px) {
    padding-left: 12px;
  }
`;
export const Wrapper = styled.div`
  /* padding:20% 20% 0 20%; */
  /* padding-top:183px; */
  /* padding-top:25%; */
  @media screen and (max-width: 1050px) {
    grid-template-columns: 1fr 1fr;
    padding: 5% 3% 0 0;
  }
`;
export const DivImg = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  grid-gap: 20px;

  @media screen and (max-width: 1050px) {
    grid-template-columns: 40% 40%;
    padding-left: 1%;
    padding-bottom: 40px;
  }
`;
export const PFirst = styled.p`
  font-family: 'avenir_nextregular';
  font-size: 16px;
  /* padding-right: 150px; */
  max-width: 318px;
  @media screen and (max-width: 1050px) {
    padding-right: 0px;
  }
`;
export const PSecond = styled.p`
  font-family: 'avenir_nextbold';
  font-size: 45px;
  line-height: 50px;
  max-width: 430px;
  text-align: left;
  color: #fff;
  margin: 20px 0px;
  @media screen and (max-width: 1050px) {
    padding-right: 0px;
  }
`;

export const IMG = styled.div<Props>`
  background-image: url(${(p) => p.image});
  background-size: contain;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  @media screen and (max-width: 1050px) {
    background-size: contain;
    justify-content: center;
    display: flex;
    min-height: 340px;
  }
`;

export const NewMessage = styled.p`
  font-family: 'avenir_nextregular';
  font-size: 20px;
  line-height: 26px;
  text-align: left;
  max-width: 430px;
  color: #fff;
`;

export const NewStrong = styled.span`
  font-family: 'avenir_nextbold';
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
`;

export const Box = styled.div`
  background-color: #ccc;

  padding: 18px 20px;
`;

export const WrapperBox = styled.div`
  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  white-space: normal;
  flex-wrap: wrap;
  padding: 18px 20px;
  border: 1px solid #707070;
`;
// export const Input = styled.input`
//   width: 100%;
//   max-width: 540px;
//   padding: 0.375rem 0.75rem;
//   font-size: 1rem;
//   font-weight: 400;
//   line-height: 1.5;
//   color: #212529;
//   background-color: #ebebeb;
//   background-clip: padding-box;
//   border: 1px solid #ced4da;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
//   border-radius: 0.375rem;
//   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//   height: 40px;
//   font-family: 'avenir_nextregular';
//   @media screen and (max-width: 1050px) {
//     width: 100% !important;
//   }
// `;
