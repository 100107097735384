//@ts-nocheck
import React, { useEffect, useState } from 'react';

import { Grid, Container, Hr, Text } from './styles';

const Page1: React.FC = () => {
  return (
    <Container>
      <Grid>
        <h1>Contact Us</h1>
        <Hr />
        <Text>Xgreen Services LLC </Text>

        <Text>
          <span>
            <strong>Address: </strong>
            300 S Orange Avenue #Ste 1000,
            <br />
            Orlando, FL, 32801, USA
          </span>
          <span>
            <strong>Phone:</strong> +1 (689) 899-0285
            <br />
            <strong>Email:</strong> hello@xgreen.app
          </span>
        </Text>
      </Grid>
    </Container>
  );
};

export default Page1;
