import React, { useState } from 'react';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';

import Page1 from '../../components/Service/Page1';
import Page2 from '../../components/Service/Page2';
import Page3 from '../../components/Service/Page3';
import Page4 from '../../components/Service/Page4';
import Page5 from '../../components/Service/Page5';

const Home: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  document.title = 'Xgreen - Cleaning Services';
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Page5 />
      {/* <Page1/>
      <Page2/>
      <Page3/>
      <Page4/> */}
      <Footer />
    </>
  );
};

export default Home;
