//@ts-nocheck
import React, { useEffect, useState } from 'react';

import { Grid, Container } from './styles';
import Accordion from './Components/Accordion';
import Tabs from './Components/Tabs';

const Page5: React.FC = () => {
  const [openAccordion, setOpenAccordion] = useState<number | null>(null);

  const handleAccordionToggle = (index: number) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const tabData = [
    {
      label: 'HANDYMAN',
      description:
        'From fixing leaky faucets to repairing broken tiles, we handle all kinds of minor and major repairs around your home. No job is too small. Our team can help install your new appliances safely and efficiently. We provide assembly for furniture, electronics, and more, as well as professional mounting for TVs and other heavy items.',
      content: (
        <>
          <Accordion
            title="General Home Repairs"
            content={{
              column1: [
                'Plumbing repairs (leaky faucets, toilet repairs) ',
                'Electrical fixes (outlet issues, change light bulbs, install chandeliers and doorbells) ',
                'Patchwork (drywall repairs, painting touch-ups)',
              ],
            }}
            isOpen={openAccordion === 0}
            onToggle={() => handleAccordionToggle(0)}
          />
          <Accordion
            title="Appliance Installation"
            content={{
              column1: [
                'Washer/dryer installation ',
                'Refrigerator setup ',
                'Dishwasher installation ',
                'Microwave mounting',
              ],
            }}
            isOpen={openAccordion === 1}
            onToggle={() => handleAccordionToggle(1)}
          />
          <Accordion
            title="Assembly and Mounting Services"
            content={{
              column1: [
                'Furniture assembly (beds, cabinets, desks) ',
                'TV mounting and wall brackets ',
                'Picture hanging ',
                'Shelving and storage installation',
              ],
            }}
            isOpen={openAccordion === 2}
            onToggle={() => handleAccordionToggle(2)}
          />
          <Accordion
            title="Emergency Handyman Services"
            content={{
              column1: [
                'Sometimes, repairs just can’t wait. We offer emergency handyman services to address urgent repairs and ensure your home or business stays safe and functional.',
              ],
            }}
            isOpen={openAccordion === 3}
            onToggle={() => handleAccordionToggle(3)}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Container>
        <Grid>
          <h5>Handyman</h5>
          <p>
            We pride ourselves on delivering top-notch handyman services that
            are reliable, affordable, and always of the highest quality.
          </p>
        </Grid>
      </Container>
      <Tabs tabs={tabData} />
    </>
  );
};

export default Page5;
