//@ts-nocheck
import React, { useEffect, useState } from 'react';

import { Grid, Container } from './styles';
import Accordion from './Components/Accordion';
import Tabs from './Components/Tabs';

const Page5: React.FC = () => {
  const [openAccordion, setOpenAccordion] = useState<number | null>(null);

  const handleAccordionToggle = (index: number) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const tabData = [
    {
      label: 'DEEP CLEAN',
      description:
        'It involves cleaning all types of dirt and grime that have accumulated over time on the deepest surfaces or corners of the home. Our Deep Cleaning Package is the most thorough cleaning service. The most significant difference is the amount of time and detail we spend cleaning what we refer to as your vertical surfaces. Vertical surfaces include baseboards, trim work, interior windows, blinds, exterior cabinets/drawers, light fixtures, and doors.',
      content: (
        <>
          <Accordion
            title="Kitchen"
            content={{
              column1: [
                'Inside/Outside of Microwave and Oven',
                'Clean and Polish Outside of Refrigerator and other Appliances',
                'Glass Surfaces',
                'Empty Trash',
                'Backsplash',
              ],
              column2: [
                'Exterior of Trash Can',
                'Sweep Floors',
                'Outside of Stove Hood',
                'High Dust Ceiling Fans, Light Fixtures and Cobwebs',
                'Mop Floors',
                'Counter Tops',
              ],
              column3: [
                'Baseboards',
                'Sink and Fixtures',
                'Windows, Windowsills, and Blinds',
                'Door and Doorframes',
                'Outside of Cabinets and Drawers',
                'Light Switch and Outlet Covers',
              ],
            }}
            isOpen={openAccordion === 0}
            onToggle={() => handleAccordionToggle(0)}
          />
          <Accordion
            title="Bathrooms"
            content={{
              column1: [
                'Shower and Bathtubs',
                'Polish Faucets',
                'Dust Furniture',
                'Mirrors and Glass Surfaces',
                'Empty Trash and Replace Liners',
                'Toilet',
              ],
              column2: [
                'Sweep and Mop Floors',
                'Counters',
                'Shelves',
                'Sink',
                'High dust fans, Air Vents, and Light Fixtures',
                'Windows, Windowsills, and Blinds',
              ],
              column3: [
                'Door and Doorframes',
                'Outside of Cabinets and Drawers',
                'Vacuum Rugs',
                'Baseboards',
                'Light Switch and Outlet Covers',
              ],
            }}
            isOpen={openAccordion === 1}
            onToggle={() => handleAccordionToggle(1)}
          />
          <Accordion
            title="Bedrooms"
            content={{
              column1: [
                'Make Beds and Change Linens',
                'Window, Windowsills, and Blinds',
                'Sweep and Mop Floors',
                'High Dust Ceiling Fans, Light Fixtures, Air Vents, and Cobwebs',
              ],
              column2: [
                'Mirrors and Glass Surfaces',
                'Shelves',
                'Empty Trash and Replace Liners',
                'Baseboards',
              ],
              column3: [
                'Dust Accessible Furniture (Vertical and Horizonal Surfaces)',
                'Vacuum Carpets and Rugs',
                'Door and Doorframes',
                'Light Switch and Outlet Covers',
              ],
            }}
            isOpen={openAccordion === 2}
            onToggle={() => handleAccordionToggle(2)}
          />
          <Accordion
            title="Common Areas"
            content={{
              column1: [
                'Empty Trash and Replace Liners',
                'High Dust Ceiling Fans, Light Fixtures, and Cobwebs',
                'Sweep and Mop Floors',
                'Counters',
              ],
              column2: [
                'Dust Accessible Furniture (Vertical and Horizonal surfaces) ',
                'Mirrors and Glass Surfaces ',
                'Shelves ',
                'Vacuum Carpets and Rugs',
              ],
              column3: [
                'Baseboards ',
                'Door and Doorframes ',
                'Windows, Windowsills, and Blinds ',
                'Light Switch and Outlet Covers',
              ],
            }}
            isOpen={openAccordion === 3}
            onToggle={() => handleAccordionToggle(3)}
          />
        </>
      ),
    },
    {
      label: 'REGULAR CLEAN',
      description:
        'The main purpose of regular cleaning is to maintain a certain level of cleanliness around your house. Generally, regular house cleanings will cost you less when compared to deep cleanings. However, since regular cleaning is more about the maintenance, it’s usually done based on a schedule. For instance, the cleaning service can come over to your house every two weeks or once a month, depending on your needs.',
      content: (
        <>
          <Accordion
            title="Kitchen"
            content={{
              column1: [
                'Inside of Microwave and Oven ',
                'Clean and Polish Outside of Refrigerator and other Appliances ',
                'Glass Surfaces ',
                'Empty Trash ',
                'Backsplash',
              ],
              column2: [
                'Sweep Floors ',
                'Outside of Stove Hood ',
                'High Dust Ceiling Fans, Light Fixtures and Cobwebs ',
                'Mop Floors ',
                'Counter Tops',
              ],
              column3: [
                'Sink and Fixtures ',
                'Windows, Windowsills, and Blinds ',
                'Outside of Cabinets and Drawers ',
                'Light Switch and Outlet Covers',
              ],
            }}
            isOpen={openAccordion === 4}
            onToggle={() => handleAccordionToggle(4)}
          />
          <Accordion
            title="Bathrooms"
            content={{
              column1: [
                'Shower and Bathtubs ',
                'Polish Faucets ',
                'Dust Furniture ',
                'Mirrors and Glass Surfaces ',
                'Empty Trash and Replace Liners ',
                'Toilet',
              ],
              column2: [
                'Sweep and Mop Floors ',
                'Counters ',
                'Shelves ',
                'Sink ',
                'High dust fans and Light Fixtures ',
                'Windows, Windowsills, and Blinds',
              ],
              column3: [
                'Door and Doorframes ',
                'Outside of Cabinets and Drawers ',
                'Vacuum Rugs ',
                'Light Switch and Outlet Covers',
              ],
            }}
            isOpen={openAccordion === 5}
            onToggle={() => handleAccordionToggle(5)}
          />
          <Accordion
            title="Bedrooms"
            content={{
              column1: [
                'Make Beds and Change Linens',
                'Window, Windowsills, and Blinds',
                'Sweep and Mop Floors',
                'High Dust Ceiling Fans, Light Fixtures and Cobwebs',
              ],
              column2: [
                'Mirrors and Glass Surfaces',
                'Shelves',
                'Empty Trash and Replace Liners',
                'Baseboards',
              ],
              column3: [
                'Vacuum Carpets and Rugs',
                'Door and Doorframes',
                'Light Switch and Outlet Covers',
              ],
            }}
            isOpen={openAccordion === 6}
            onToggle={() => handleAccordionToggle(6)}
          />
          <Accordion
            title="Common Areas"
            content={{
              column1: [
                'Empty Trash and Replace Liners',
                'High Dust Ceiling Fans, Light Fixtures, and Cobwebs',
                'Sweep and Mop Floors',
                'Counters',
              ],
              column2: [
                'Dust Accessible Furniture (Vertical and Horizonal surfaces)',
                'Mirrors and Glass Surfaces',
                'Shelves',
                'Vacuum Carpets and Rugs',
              ],
              column3: [
                'Door and Doorframes',
                'Windows, Windowsills, and Blinds',
                'Light Switch and Outlet Covers',
              ],
            }}
            isOpen={openAccordion === 7}
            onToggle={() => handleAccordionToggle(7)}
          />
        </>
      ),
    },
    {
      label: 'OFFICE CLEANING',
      description:
        "Office maintenance and cleaning. This involves cleaning larger areas with more foot traffic, often requiring specialized cleaning equipment and methods to maintain a sanitary and safe environment. A clean and professional-looking workplace is essential to a company's image, reputation, and health and safety concerns.",
      content: (
        <>
          <Accordion
            title="Areas and Rooms"
            content={{
              column1: [
                'Open plan office areas ',
                'Management offices ',
                'Boardrooms ',
                'Meeting rooms',
              ],
              column2: [
                'Work/desk areas ',
                'Receptions ',
                'Stairways ',
                'Lifts ',
                'Windows and glass.',
              ],
              column3: [],
            }}
            isOpen={openAccordion === 8}
            onToggle={() => handleAccordionToggle(8)}
          />
          <Accordion
            title="Amenities"
            content={{
              column1: [
                'Toilets',
                'Urinals',
                'Hand basins (sinks)',
                'Showers',
                'Benches',
                'Glass mirrors',
              ],
              column2: [
                'Emptying bins',
                'Re-stocking consumables',
                'Wiping down and scrubbing surfaces',
                'Vacuuming',
                'Mopping',
              ],
              column3: [],
            }}
            isOpen={openAccordion === 9}
            onToggle={() => handleAccordionToggle(9)}
          />
          <Accordion
            title="Staff Breakout Rooms"
            content={{
              column1: [
                'Kitchens',
                'Lunchrooms',
                'Recreation rooms',
                'Games rooms',
                'Lounge areas',
              ],
              column2: [
                'Removing waste/emptying bins',
                'Wiping down surfaces',
                'Vacuuming',
                'Mopping',
              ],
              column3: [],
            }}
            isOpen={openAccordion === 10}
            onToggle={() => handleAccordionToggle(10)}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Container>
        <Grid>
          <h5>Xgreen Services</h5>
          <p>
            We’re proud to provide safe and reliable professional-grade cleaning
            supplies and products. Also we offer multiple cleaning packages that
            are designed to meet the needs of any home owner. A comprehensive
            description of each cleaning package is below.
          </p>
        </Grid>
      </Container>
      <Tabs tabs={tabData} />
    </>
  );
};

export default Page5;
