import styled from 'styled-components';
import serviceimg from '../../../assets/services.png';
type Props = {
  image: any;
};
export const Container = styled.div`
  /* box-sizing: border-box; */
  height: calc(100vh - 130px); // Subtrai 130px da altura total da viewport
  width: 100vw; // Garante que a largura ocupe toda a viewport
  background-color: #e2e2e2 !important;
  background-image: url(${serviceimg}); // Usando a variável serviceimg
  background-size: cover; // Ajusta a imagem para cobrir todo o container
  background-position: center; // Centraliza a imagem
  background-repeat: no-repeat; // Não repete a imagem
  display: flex; /* Ativa o layout flex */
  flex-wrap: wrap; /* Permite que os itens quebrem para a próxima linha */

  justify-content: center; /* Centraliza os itens horizontalmente */
  align-items: center;
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  height: 100%;
  flex: 0 0 calc(50% - 10px);
`;

export const Card = styled.a`
  width: 318px;
  height: 120px;
  border-radius: 25px;
  opacity: 1;
  background-color: #f8f8f8;
  justify-content: center;
  align-items: center;
  display: flex;
  font-family: 'avenir_nextbold';
  font-size: 20px;
  /* text-align: left; */
  line-height: 27px;
  /* word-break: break-all; */
  overflow-wrap: break-word;
  /* margin: 72px 32px; */
  cursor: pointer;
  color: #000 !important;
  @media screen and (max-width: 1280px) {
    width: 290px;
  }
`;
export const DivText = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
  padding-left: 25%;
  /* width: 100%; */
  @media screen and (max-width: 1050px) {
    padding-left: 0px;
  }
`;
export const Wrapper = styled.div`
  /* padding:20% 20% 0 20%; */
  /* padding-top:183px; */
  /* padding-top:25%; */
  @media screen and (max-width: 1050px) {
    grid-template-columns: 1fr 1fr;
    padding: 5% 3% 0% 3%;
  }
`;
export const DivImg = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  grid-gap: 20px;

  @media screen and (max-width: 1050px) {
    grid-template-columns: 40% 40%;
    padding-left: 1%;
    padding-bottom: 40px;
  }
`;
export const PFirst = styled.p`
  font-family: 'avenir_nextregular';
  font-size: 16px;
  /* padding-right: 150px; */
  max-width: 318px;
  @media screen and (max-width: 1050px) {
    padding-right: 0px;
  }
`;
export const PSecond = styled.p`
  font-family: 'avenir_nextbold';
  font-size: 50px;
  line-height: 50px;
  max-width: 407px;
  @media screen and (max-width: 1050px) {
    padding-right: 0px;
    max-width: 375px;
    margin: 0px;
    margin-bottom: 20px;
  }
`;

export const IMG = styled.div<Props>`
  background-image: url(${(p) => p.image});
  background-size: contain;
  width: 430px;
  height: 450px;
  background-repeat: no-repeat;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -110px;
  @media screen and (max-width: 1050px) {
    background-size: contain;
    justify-content: center;
    display: flex;
    min-height: 340px;
  }
`;

export const WrapperImg = styled.div`
  height: 70%;
  border: none;
  margin-left: 10%;
  background-color: #fff;
  width: 654px;
  border-radius: 15px;
  height: 554px;
  display: flex;
  margin-left: 220px;
  flex-direction: row;
  @media screen and (max-width: 1050px) {
    display: none;
  }
`;

export const Combo = styled.div`
  width: 30%;
  flex-direction: column;
  padding: 90px 0 0 50px;
  display: flex;
  justify-content: center;

  p {
    font-family: 'avenir_nextbold';
    font-size: 20px;
    text-align: left;
    line-height: 27px;
  }
  span {
    font-family: 'avenir_nextregular';
    font-size: 16px;
    text-align: left;
    line-height: 26px;
  }
`;

export const WrapperImgMobile = styled.div`
  display: none;
  @media screen and (max-width: 1050px) {
    background-size: contain;
    justify-content: center;
    display: flex;
    min-height: 450px;
    width: 90%;
    border-radius: 15px;
  }
`;

export const ComboMobile = styled.div`
  display: flex;
  /* width: 100%; */
  flex-direction: column;
  /* padding:90px 0 0 50px; */
  background-color: #fff;
  border-radius: 25px;
  p {
    font-family: 'avenir_nextbold';
    font-size: 20px;
    text-align: left;
    line-height: 27px;
    padding: 10px 10px 0px 25px !important;
  }
  span {
    font-family: 'avenir_nextregular';
    font-size: 16px;
    text-align: left;
    line-height: 26px;
    padding: 10px 10px 20px 25px !important;
  }
`;

export const IMGMobile = styled.div<Props>`
  background-image: url(${(p) => p.image});
  background-size: contain;
  /* width: 100%; */
  /* height: 305px; */
  /* background-repeat: no-repeat; */
  /* align-self:center; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: -110px; */
  border-radius: 25px;
  @media screen and (max-width: 1050px) {
    background-size: 103% 100%;
    justify-content: center;
    display: flex;
    height: 200px !important;
    background-repeat: no-repeat;
  }
`;
