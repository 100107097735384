//@ts-nocheck
import React, { useEffect, useState } from 'react';

import {
  Grid,
  Container,
  Wrapper,
  WrapperIcon,
  Content,
  WrapperApp,
} from './styles';
import imgHome from '../../../assets/images/work/page1/professional_.png';
import imgHomeMobile from '../../../assets/images/work/page1/mobile/professional_.png';

import calendar from '../../../assets/images/work/page2/calendar-event/calendar-event.png';
import alarm from '../../../assets/images/work/page2/alarm/alarm.png';
import diamond from '../../../assets/images/work/page2/diamond/diamond.png';
import user from '../../../assets/images/work/page2/user/user.png';

import apple from '../../../assets/images/apple@2x.png';
import android from '../../../assets/images/android@2x.png';
const Page1: React.FC = () => {
  const data = [
    {
      icon: calendar,
      title: 'Flexible Schedule',
      subTitle: 'Work during the week without giving up your nights.',
    },
    {
      icon: user,
      title: 'Support & Supplies',
      subTitle:
        'We provide thorough training, uniforms and safe, effective cleaning supplies.',
    },
    {
      icon: alarm,
      title: 'Freedom',
      subTitle: 'Work by yourself, staying active and on the move all day.',
    },
    {
      icon: diamond,
      title: 'Career Path',
      subTitle: 'You can grow your career with Xgreen Provider.',
    },
  ];

  return (
    <Container>
      <Grid>
        <Wrapper image={imgHome} imageMobile={imgHomeMobile}>
          <p>Don’t miss the opportunity.</p>
          <p>Want to be an entrepreneur at Xgreen?</p>
          <p> We provide everything for you, from equipment to customers.</p>
          <p>Download the App</p>
          <WrapperApp>
            <a
              href="https://play.google.com/store/apps/details?id=com.mindconsulting.xgreen&hl=en"
              target="_blank"
            >
              <img src={android} style={{ height: '60px' }} />
            </a>
            <a
              href="https://apps.apple.com/br/app/xgreen/id6473839103"
              target="_blank"
            >
              <img src={apple} style={{ height: '60px', cursor: 'pointer' }} />
            </a>
          </WrapperApp>
        </Wrapper>

        <WrapperIcon>
          {data.map((value: any, index: number) => {
            return (
              <Content style={{ marginBottom: '40px' }} key={index}>
                <img src={value.icon} />
                <div style={{ marginLeft: '20px' }}>
                  <p>{value.title}</p>
                  <span>{value.subTitle}</span>
                </div>
              </Content>
            );
          })}
        </WrapperIcon>
      </Grid>
    </Container>
  );
};

export default Page1;
