//@ts-nocheck
import React, { useEffect, useState } from 'react';

import {
  Grid,
  Container,
  Wrapper,
  Icon,
  WrapperIcon,
  WrapperLocation,
  WrapperApp,
  Wrapper1,
  WrapperCopy,
  Text,
  Hr,
  WrapperButton,
  ButtonGreen,
  ButtonPurple,
  Linkpdf,
} from './styles';

import facebook from '../../assets/images/medias/facebook.png';
import instagram from '../../assets/images/medias/insta.png';
import linkedin from '../../assets/images/medias/linkedin.png';
import tiktok from '../../assets/images/medias/TikTok-icon-white@2x.png';
import apple from '../../assets/images/apple.png';
import android from '../../assets/images/android.png';
import { Link } from 'react-router-dom';
import pdf from '../../assets/xgreen_termsPolicy_202405.pdf';
import maps from '../../assets/googleMaps_@2x.png';
import review from '../../assets/review.jpeg';
const Footer: React.FC = () => {
  return (
    <Container>
      <Grid>
        <Wrapper style={{ width: '75%' }}>
          <h1>SOCIAL MEDIA</h1>
          <hr
            style={{
              border: '0.5px solid #FFFFFF',
              width: '100%',
              opacity: '0.5',
              textAlign: 'left',
            }}
          />

          <WrapperIcon>
            <Icon>
              <a href="https://www.linkedin.com/company/xgreen" target="_blank">
                <img src={linkedin} style={{ width: '22.5px' }} />
                <p>LinkedIn</p>
              </a>
            </Icon>

            <Icon>
              <a href="https://www.facebook.com/xgreenapp/" target="_blank">
                <img src={facebook} style={{ width: '11.37px' }} />
                <p>Facebook</p>
              </a>
            </Icon>

            <Icon>
              <a href=" https://www.instagram.com/xgreen.app" target="_blank">
                <img src={instagram} style={{ width: '22.78px' }} />
                <p>Instagram</p>
              </a>
            </Icon>

            <Icon>
              <a
                href="https://www.tiktok.com/@xgreenusa?is_from_webapp=1&sender_device=pc"
                target="_blank"
              >
                <img src={tiktok} style={{ width: '21.5px', height: '24px' }} />
                <p>TikTok</p>
              </a>
            </Icon>
          </WrapperIcon>
          {/* <img
            src={review}
            style={{ height: '52px', width: '94px' }}
            onClick={() => window.open('https://g.co/kgs/BPQuc2f', '_blank')}
          /> */}
        </Wrapper>

        <Wrapper>
          <div style={{ flexDirection: 'row', display: 'flex', gap: '15px' }}>
            <a
              href="https://www.google.com/maps/place/Xgreen/@28.5388508,-81.3819487,17z/data=!3m1!4b1!4m6!3m5!1s0x43a52ec7841cc001:0xa8a9094e9918ad89!8m2!3d28.5388461!4d-81.3793684!16s%2Fg%2F11w8fbd1tl?entry=ttu&g_ep=EgoyMDI0MDgyNi4wIKXMDSoASAFQAw%3D%3D"
              target="_blank"
            >
              <img
                src={maps}
                style={{ height: '23px', width: '23px', cursor: 'pointer' }}
              />
            </a>
            <h1>HEADQUARTER</h1>
          </div>
          <hr
            style={{
              border: '0.5px solid #FFFFFF',
              width: '100%',
              opacity: '0.5',
            }}
          />

          <WrapperLocation>
            <h2>Orlando/FL - USA</h2>
            <span>
              Xgreen Services LLC
              <br />
              300 S Orange Avenue #Ste 1000, Orlando, FL, 32801, USA.
              <br />
              Phone: +1 (689) 899-0285
              <br />
              <a href="mailto: hello@xgreen.app">Email: hello@xgreen.app</a>
            </span>
          </WrapperLocation>
        </Wrapper>

        <Wrapper1>
          <h1>BOOK IT</h1>
          <hr
            style={{
              border: '0.5px solid #FFFFFF',
              width: '100%',
              opacity: '0.5',
            }}
          />
          <WrapperButton>
            <ButtonGreen>
              <a href="https://dashboard.xgreen.app" target="_blank">
                BOOK NOW
              </a>
            </ButtonGreen>

            <Link to="/rewards">
              <ButtonPurple>REWARDS</ButtonPurple>
            </Link>
          </WrapperButton>
        </Wrapper1>
      </Grid>
      <Hr />
      <WrapperCopy style={{}}>
        <div>
          <p>Copyright © 2021 Xgreen. All rights reserved.</p>
        </div>
        <div>
          <span>
            <Linkpdf
              href={
                'https://app.termly.io/policy-viewer/policy.html?policyUUID=f509d5d9-ecfd-4b9d-bec9-da737b755d75'
              }
              target="_blank"
            >
              Terms and Conditions
            </Linkpdf>{' '}
            |{' '}
            <Linkpdf
              href={
                'https://app.termly.io/policy-viewer/policy.html?policyUUID=39b98d1a-8c64-4744-8932-09b146a0cdae'
              }
              target="_blank"
            >
              Privacy Policy
            </Linkpdf>{' '}
            |{' '}
            <Linkpdf
              href={
                'https://app.termly.io/policy-viewer/policy.html?policyUUID=b7e81c2c-0cde-4b4c-a0b2-8b8eb372c6b4'
              }
              target="_blank"
            >
              Disclaimer
            </Linkpdf>{' '}
            |{' '}
            <Linkpdf
              href={
                'https://app.termly.io/policy-viewer/policy.html?policyUUID=7d73b774-3e35-470e-9643-bbcdc22d712f'
              }
              target="_blank"
            >
              Return & Refund Policy
            </Linkpdf>{' '}
            |{' '}
            <Linkpdf href={window.location.origin + '/payment-policy'}>
              Payment Policy
            </Linkpdf>
          </span>
        </div>
        <Text>
          {/* Terms and Conditions | Privacy Policy | Disclaimer | Return & Refund Policy| | Payment Policy */}
          <WrapperApp>
            <a
              href="https://play.google.com/store/apps/details?id=com.mindconsulting.xgreen&hl=en"
              target="_blank"
              style={{ cursor: 'pointer' }}
            >
              <img src={android} />
            </a>
            <a
              href="https://apps.apple.com/br/app/xgreen/id6473839103"
              target="_blank"
              style={{ cursor: 'pointer' }}
            >
              <img src={apple} />
            </a>
          </WrapperApp>
        </Text>
      </WrapperCopy>
    </Container>
  );
};

export default Footer;
