//@ts-nocheck
import React, { useEffect, useState } from 'react';

import {
  Grid,
  Container,
  WrapperStar,
  Box,
  WrapperPerson,
  BoxPerson,
  BoxText,
  Hr,
} from './styles';
import star from '../../../assets/images/star.svg';
import starfull from '../../../assets/images/starfull.svg';

const data = [
  {
    star: 5,
    name: 'Flávia PC',
    text: 'I definitely recommend Xgreen! The App is very easy to use and so efficient. The team was so on time and kind. They asked if I had any recommendations regarding cleaning my house and explained what they were going to do. The cleaning was very well done, in every detail. In the end, they were concerned about asking if everything was in order and if anything else was needed. They exceeded my expectations.',
  },
  {
    star: 5,
    name: 'Beatrice Ang',
    text: 'I recently hired Xgreen, and I couldn’t be more impressed. From the moment I contacted them, their customer service was professional, friendly, and efficient. The cleaning team arrived on time and was incredibly thorough, leaving my home great!<br/><br/> They paid attention to every detail. What really stood out was their dedication to making sure everything was perfect before they left. They exceeded my expectations, and I highly recommend Xgreen to anyone!',
  },
  {
    star: 5,
    name: 'Carla Rodriguez',
    text: 'I had xgreen come for a deep clean, and I was really impressed! They didn’t just do the basics, they took the time to make my cooktop look amazing. The team was super friendly. It was a great experience. I’d definitely recommend!!',
  },
  {
    star: 5,
    name: 'Ana Paula Carol',
    text: 'I had Xgreen in my house for a regular clean service . The girls did it really great ! I became impressed and really recomend them !',
  },
  {
    star: 5,
    name: 'Miriam Patez Vivone',
    text: 'Great cleaning service! Flawless, professional, and left my home spotless. Highly recommend!',
  },
];

const renderStar = (number: number) => {
  const listItems = [];
  for (let i = 1; i <= 5; i++) {
    if (i <= number) {
      listItems.push(<img src={starfull} alt={'star'} />);
    } else {
      listItems.push(<img src={star} alt={'star'} />);
    }
  }
  return listItems;
};

const Page1: React.FC = () => {
  return (
    <Container>
      <h5>Reviews</h5>
      <Grid>
        {data.map((value, index) => {
          return (
            <Box key={index}>
              <BoxText>
                <p>{value.name}</p>
                <WrapperStar>{renderStar(value.star)}</WrapperStar>
                <p>
                  {<div dangerouslySetInnerHTML={{ __html: value.text }} />}
                </p>
              </BoxText>
              {index < 4 ? <Hr /> : null}
            </Box>
          );
        })}
      </Grid>
    </Container>
  );
};

export default Page1;
