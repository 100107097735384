import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input';
import { toast } from 'react-toastify';

const Container = styled.div`
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
  padding: 18px 20px;
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 8px;
  font-family: Arial, sans-serif;

  @media screen and (max-width: 600px) {
    width: 85%;
    margin-bottom: 40px;
  }
`;

const Title = styled.h2`
  font-family: 'avenir_nextbold';
  font-size: 20px;
  text-align: left;
  line-height: 27px;
  margin-bottom: 27px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const InputAndSelectStyle = `
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
  height:50px;
  ::placeholder {
    font-family: 'avenir_nextregular'
    font-size: 16px;
    
  }
`;

const Select = styled.select`
  ${InputAndSelectStyle}
  color: #707070;
  appearance: none; /* Esconde a setinha padrão */
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="%23707070" stroke-width="2" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5" /></svg>')
    no-repeat right 0px center; /* Adiciona um chevron vazio para baixo */
  background-size: 32px; /* Tamanho do chevron */

  option {
    color: black;
  }
`;

const Input = styled.input`
  ${InputAndSelectStyle}
`;

const Button = styled.button`
  padding: 15px;
  background-color: #9dfdca;
  border: none;
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: bold;
  color: black;
  cursor: pointer;
  font-family: 'avenir_nextbold';
  font-size: 20px;
  text-align: center;
  line-height: 27px;
  /* &:hover {
    background-color: #8fdfa1;
  } */
`;

const QuickQuoteForm: React.FC = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    reset,
  } = useForm({
    mode: 'onBlur', // "onChange"
  });
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const dados = {
      mensagem: `
        Type Of Cleaning: ${data.typeOfCleaning} \r\n
        Bedrooms: ${data.bedrooms} \r\n
        Bathrooms: ${data.bathrooms} \r\n
        Email: ${data.email} \r\n
        Phone: ${data.phone} \r\n

      `,
      auth: '3f018bfb43591ccb2ef46f10de832160',
      assunto: 'Form Quote Xgreen',
      destino: 'hello@xgreen.app',
    };
    try {
      const response = await axios.post(
        'https://xgreen.app/email/send.php',
        dados
      );
      if (parseInt(response.data) == 1) {
        toast.success('Email sent successfully', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        reset({
          typeOfCleaning: '',
          bedrooms: '',
          email: '',
          bathrooms: '',
          phone: '',
        });
      } else {
        toast.error('Try again', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Title>Get A Quick Quote!</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Select
            defaultValue=""
            {...register('typeOfCleaning', { required: true })}
          >
            <option value="" disabled>
              Type of Cleaning*
            </option>
            <option>Weekly Cleaning</option>
            <option>Bi-Weekly Cleaning</option>
            <option>Monthly Cleaning</option>
            <option>Once Time Cleaning</option>
          </Select>
        </div>

        <div>
          <Select defaultValue="" {...register('bedrooms', { required: true })}>
            <option value="" disabled>
              Number of Bedrooms*
            </option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
            <option>8</option>
            <option>9</option>
          </Select>
        </div>

        <div>
          <Select
            defaultValue=""
            {...register('bathrooms', { required: true })}
          >
            <option value="" disabled>
              Number of Bathrooms*
            </option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
            <option>8</option>
            <option>9</option>
          </Select>
        </div>

        <div>
          <Input
            type="email"
            placeholder="Email*"
            {...register('email', { required: true })}
          />
        </div>

        <div>
          <Input
            type="tel"
            placeholder="Phone*"
            {...register('phone', { required: true })}
          />
          {/* <PhoneInput
            international
            defaultCountry="RU"
            value={phone}
            required
            onChange={(e:any) => setPhone(e)}
          /> */}
        </div>

        <Button type="submit" disabled={isLoading}>
          GET A QUOTE
        </Button>
      </Form>
    </Container>
  );
};

export default QuickQuoteForm;
