import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import bg from '../../../assets/images/day/background_@2x.png';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: black;
    color: white;
    font-family: 'avenir_nextregular';
  }
`;

const BgImg = styled.div`
  /* display: flex; */
  background-image: url(${bg});
  /* background-position: center; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: calc(100vh - 130px);
  object-fit: contain;
  @media screen and (max-width: 600px) {
    height: auto;
    background-position: center top;
    background-size: auto;
    padding: 0 20px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  max-width: 1280px;
  margin: auto;
  gap: 80px;

  @media screen and (max-width: 600px) {
    flex-direction: column; /* Mudança para coluna em telas menores */
    align-items: center; /* Centralizar conteúdo */

    margin: 0px !important;
    width: 100%;
    height: auto !important;
    gap: 0px;
  }
`;

const WrapperBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 80px;
  @media screen and (max-width: 600px) {
    flex-direction: column; /* Mudança para coluna em telas menores */
    align-items: center; /* Centralizar conteúdo */
    margin: 0;
    width: 100%;
  }
`;
const BoxDay = styled.div`
  width: 420px;
  flex-direction: column;
  justify-content: flex-start;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const BoxText = styled.div`
  width: 650px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
export const Highlight = styled.span`
  font-weight: bold;
  color: #9dfdca; // Tailwind's green-400
`;
const RuleParagraph = styled.div`
  margin: auto;
  max-width: 1150px;
  p {
    font-size: 20px;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 50px;
  }
`;
const Page1 = () => {
  return (
    <BgImg>
      <GlobalStyle />
      <Container>
        <WrapperBox>
          <BoxDay>
            <p
              style={{
                color: '#fff',
                fontSize: '45px',
                fontFamily: 'avenir_nextbold',
              }}
            >
              Xgreen Day.
            </p>
            <p
              style={{
                color: '#9DFDCA',
                fontSize: '45px',
                fontFamily: 'avenir_nextbold',
              }}
            >
              Giving Back to
              <br /> the Community
            </p>
          </BoxDay>
        </WrapperBox>
        <WrapperBox>
          <BoxText>
            <p style={{ fontSize: '20px' }}>
              At Xgreen, we believe in the power of community and the importance
              of lending a helping hand. That’s why,{' '}
              <Highlight>
                *starting every first Saturday of the month, we are proud to
                offer 3 free home cleanings*
              </Highlight>{' '}
              to individuals who may be facing financial challenges or who are
              of advanced age.
              <br />
              <br />
              <br /> If you or someone you know could benefit from this service,
              please reach out to us at <Highlight>hello@xgreen.app</Highlight>.
              Share a brief description of your situation and why a free
              cleaning would make a difference in your life. Residences must be
              a maximum of *2,000 square feet*
            </p>
          </BoxText>
        </WrapperBox>
      </Container>
      {/* <Container>
        <WrapperBox>
          <RuleParagraph>
            <p>
              We understand that a clean home can bring comfort and joy,
              especially for those who may struggle to keep up with daily
              chores. This initiative is our heartfelt way of giving back,
              reminding us all that even small acts of kindness can create a
              ripple effect of positivity. Together, we can cultivate a more
              compassionate community—one where everyone feels appreciated and
              supported—because everyone deserves a little extra care and
              attention.
            </p>
          </RuleParagraph>
        </WrapperBox>
      </Container> */}
    </BgImg>
  );
};

export default Page1;
